@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

*{padding: 0px; margin: 0px;}

.theme-samsung {

    background-color: #fff;
    
    // navbar
    --bg-navbar: #fff;
    --border-navbar: #fff;

    --color-dark: #0c0c0c;
    --color-primary:  #141414;
    --color-secondary: #2189ff;
    --color-light: #ffff;

    // button
    --btn-dark: var(--color-dark);
    --btn-dark-border: var(--color-dark);
    --btn-dark-text: #fff;

    --btn-primary: var(--color-primary);
    --btn-primary-border: var(--color-primary);
    --btn-primary-text: #fff;

    --btn-secondary: var(--color-secondary);
    --btn-secondary-border: var(--color-secondary);
    --btn-secondary-text: #fff;

    --btn-light: var(--color-light);
    --btn-light-border: var(--color-light);
    --btn-light-text: #000;

    // header call to action
    --header-bg-overlay: linear-gradient(180deg, rgb(61 61 61 / 15%) 0%, rgb(14 14 14 / 74%) 100%);
    --header-color-text-small: #CACACA;
    --header-call-to-action: #0c0c0c;
    --header-call-to-action-text: #fff;

    // footer
    --bg-footer-cta: #262626;
    --color-footer-cta: #fff;

    --featured-badge: var(--color-secondary);
    --featured-border: var(--color-secondary);
    --featured-text: var(--color-secondary);

    --middle-support-fulltime-bg: var(--color-secondary);
    --middle-support-fulltime-text: #fff;

    --color-text-field: var(--color-secondary);
}
